import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormLabel from '@material-ui/core/FormLabel'
import LabelWithAsterisk from './LabelWithAsterisk'

import {} from '../../actions'

const styles = theme => ({
  root: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    padding: '.5rem 0',
    marginTop: 0,
    marginBottom: 0,
  },
  external: {
    marginBottom: 0,
    paddingBottom: 0,
    alignSelf: 'flex-end'
  },
  floatRight: {
    display: 'block',
    textAlign: 'right',
    flexGrow: 1
  },
  small: {
    fontWeight: 400,
    fontSize: '.75rem',
  }
})

class Label extends React.Component {
  render() {
    const { classes, field } = this.props

    return (
      <FormLabel className={classes.root + (field.external ? ' ' + classes.external : '') + (field.float === 'right' ? ' ' + classes.floatRight : '')}>
        <LabelWithAsterisk>{field.label}</LabelWithAsterisk>
        {field.small ? (
          <small className={classes.small}><br/>{field.small}</small>
        ) : null}
      </FormLabel>
    )
  }
}

Label.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Label)))
