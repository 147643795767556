import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import LabelWithAsterisk from './LabelWithAsterisk'

import {} from '../../actions'

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxHeight: 490
  },
  label: {
    fontWeight: 600,
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    marginTop: 9
  },
  tableCell: {
    paddingRight: 0,
    paddingLeft: 0,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  tableCellActions: {
    textAlign: 'right'
  },
  cellWithStyles: {
    paddingRight: 12,
    paddingLeft: 12,
  },
  tableContainer: {
    maxHeight: 465,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableCellHead: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.common.white,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -1,
      left: -1,
      right: -1,
      height: 1,
      backgroundColor: '#e0e0e0'
    }
  },
  withStyles: {
    margin: -12,
    padding: 12,
  }
})

class Preview extends React.Component {
  render() {
    const { field, classes, appConfig } = this.props

    if (!field.value) {
      return null
    }

    const { head, body, withActions } = field.value

    return (
      <div className={classes.root + (field.styles ? ' ' + classes.withStyles : '')} style={field.styles || {}}>
        {field.label ? (
          <div className={classes.label}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></div>
        ) : ''}
        <div className={classes.tableContainer}>
          {head && body ? (
            <Table>
              <TableHead>
                <TableRow>
                  {head.map((item, index) => {
                    return (
                      <TableCell className={classes.tableCell + ' ' + classes.tableCellHead + (field.styles ? ' ' + classes.cellWithStyles : '')} key={index}>{item}</TableCell>
                    )
                  })}
                  {withActions ? (
                    <TableCell className={classes.tableCell + ' ' + classes.tableCellHead + (field.styles ? ' ' + classes.cellWithStyles : '')}></TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {body.map((cells, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      {head.map((item, cellIndex) => {
                        const cell = cells[cellIndex]

                        return (
                          <TableCell className={classes.tableCell + (field.styles ? ' ' + classes.cellWithStyles : '')} key={cellIndex}>{cell}</TableCell>
                        )
                      })}
                      {withActions ? cells[head.length] ? (
                        <TableCell className={classes.tableCell + ' ' + classes.tableCellActions + (field.styles ? ' ' + classes.cellWithStyles : '')}>
                          {cells[head.length].map((action, actionIndex) => {
                            switch (action.type) {
                              case 'icon':
                                return (
                                  <IconButton key={actionIndex} style={action.styles || {}} component={NavLink} to={appConfig.appPrefix + action.action}><Icon>{action.icon}</Icon></IconButton>
                                )
                              default:
                                return (
                                  <Button key={actionIndex} component={NavLink} to={action.action}>{action.icon ? (<Icon>{action.icon}</Icon>) : null}{action.label ? action.label : null}</Button>
                                )
                            }
                          })}
                        </TableCell>
                      ) : (
                        <TableCell className={classes.tableCell + (field.styles ? ' ' + classes.cellWithStyles : '')}></TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          ) : ''}
        </div>
      </div>
    )
  }
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Preview)))
