import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '../Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

import {
  confirmActions,
  commonActions,
  formActions
} from '../../actions'

const styles = theme => ({
  tableCol: {
    padding: '0 4px',
    fontWeight: 600,
    '&:last-child': {
      paddingRight: 0,
    }
  },
  icon: {
    fontSize: 12,

  },
  lastCell: {
    textAlign: 'right'
  },
  tableHead: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  tableRow: {
    height: 'auto',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  tableRowHead: {
    backgroundColor: theme.palette.common.white + '!important',
  },
  divider: {
    margin: '1rem 0'
  },
  iconButton: {
    marginRight: theme.spacing.unit
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  actionAdd: {
    backgroundColor: theme.palette.success[100]
  },
  button: {
    marginLeft: theme.spacing.unit
  },
  tooltipRoot: {
    fontSize: '1rem'
  }
})

class Preview extends React.Component {
  onClick = (action, index, listIndex) => {
    const { onDelete, onRemoveFromList } = this.props

    onDelete({
      url: action.action,
      data: action.params
    }, () => {
      return onRemoveFromList(index, listIndex)
    }, {
      index
    })
  }

  getActions = (actions, itemIndex, listIndex) => {
    const { classes, appConfig } = this.props

    return (
      <React.Fragment>
        {actions.map((action, index) => {
          let actionProps = {
            component: NavLink,
            to: appConfig.appPrefix + action.action
          }

          if (action.type === 'delete') {
            actionProps = {
              onClick: () => this.props.onHandleOpen(action.confirm, this.onClick, [action, itemIndex, listIndex])
            }
          }

          const hasTooltip = action.buttonType === 'button' ? !(!action.tooltip) : !(!action.label)

          const actionButton = (
            <IconButton key={index} {...actionProps} style={{
              color: action.icon === 'pen-to-square' ? '#428bca' : action.iconColor
            }}>
              <Icon className={classes.icon}>{action.icon}</Icon>
            </IconButton>
          )

          if (hasTooltip) {
            return (
              <Tooltip title={action.tooltip || action.label} classes={{
                tooltip: classes.tooltipRoot
              }} placement='left' key={index}>
                {actionButton}
              </Tooltip>
            )
          }

          return actionButton
        })}
      </React.Fragment>
    )
  }

  render() {
    const { classes, list, appConfig, history } = this.props

    const visitsData = Array.isArray(list) ? list : []

    const renderActions = (actions) => (
      actions && actions.map((action, index) => {
        let tmpProps = {
          component: NavLink,
          to: appConfig.appPrefix + action.action
        }
  
        if (action.confirm) {
          tmpProps = {
            onClick: () => this.props.onHandleOpen(action.confirm, (to) => {
              return history.push(to)
            }, [appConfig.appPrefix + action.action])
          }
        }
  
        return (
          <Button variant='contained' className={classes.button} style={action.styles} {...tmpProps} key={index}>
            <Icon className={classes.iconButton}>{action.icon}</Icon> {action.label}
          </Button>
        )
      })
    )

    return (
      <div className={classes.root}>
        {visitsData.map((item, listIndex) => (
          <div key={`${item.type}_${listIndex}`}>
            <div className={classes.actions}>
              {renderActions(item.actions)}
            </div>
            <Divider className={classes.divider} />
            {this.renderTable(item, listIndex)}
            <div style={{ margin: '25px 0' }} />
          </div>
        ))}
      </div>
    )
  }

  renderTable(visitData, listIndex) {
    const { classes } = this.props

    return (
      <>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableRow + ' ' + classes.tableRowHead}>
              {visitData.head.map((item, index) => (
                <TableCell key={index} className={classes.tableCol}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visitData.body.map((row, rowIndex) => (
              <TableRow key={rowIndex} className={classes.tableRow}>
                {visitData.head.map((item, index) => {
                  const isLast = index === visitData.head.length - 1

                  const rowItem = row.fields[index] || this.getActions(row.actions, rowIndex, listIndex)

                  return (
                    <TableCell key={rowIndex + '-' + index} className={classes.tableCol + (isLast ? ' ' + classes.lastCell : '')}>
                      {rowItem}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    )
  }
}

Preview.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onHandleOpen: (config, callback, callbackParams) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  },
  onDelete: (config, callback) => {
    dispatch(commonActions.callPost(config, callback))
  },
  onRemoveFromList: (index, listIndex) => {
    dispatch(formActions.removeFromList(index, listIndex))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Preview)))
