import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ActionReminderField from '../ActionReminderField'

import {
} from '../../actions'

const styles = theme => ({})

class ActionReminder extends React.Component {
  render() {
    const { item } = this.props

    return (
      <ActionReminderField 
        icon={item.icon}
        title={item.title}
        description={item.description}
        backgroundColor={item.backgroundColor}
        titleFontSize={item.titleFontSize}
        descriptionFontSize={item.descriptionFontSize}
        iconSize={item.iconSize}
        layout={item.layout}
      />
    )
  }
}

ActionReminder.propTypes = {}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ActionReminder)))
