import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { validators } from '../Validators'
import Select from 'react-select'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import _ from 'lodash'
import LabelWithAsterisk from '../LabelWithAsterisk'

import {} from '../../../actions'

const styles = theme => ({
  root: {
    marginTop: 0,
    marginBottom: 0,
  },
  inGroup: {
    marginBottom: '.5rem'
  },
  required: {
    color: '#ff0000',
    marginLeft: 2
  },
  label: {
    color: theme.palette.common.black,
    fontWeight: 600,
    textTransform: 'uppercase',
    top: -7,
    '&::before': {
      content: '""',
      position: 'absolute',
      height: 3,
      top: '50%',
      marginTop: -1,
      left: -5,
      right: -5,
      zIndex: -1,
      backgroundColor: theme.palette.common.white
    }
  },
  labelActive: {
    transform: 'translate(14px, 0px)',
  },
  formHelperText: {
    padding: '0 .75rem',
    position: 'absolute',
    top: '100%',
    marginTop: '2px'
  }
})

const customStyles = (config, isColored) => {
  const newCustomStyles = config.customStyles || {}

  const defaultCustomStyles = {
    control: (provided, state) => ({}),
    menu: (provided, state) => ({}),
    dropdownIndicator: (provided, state) => ({}),
    singleValue: (provided, state) => ({}),
    valueContainer: (provided, state) => ({}),
    indicatorSeparator: (provided, state) => ({}),
    ...newCustomStyles
  }

  const {
    appendToCustomStyles
  } = config

  return {
    control: (provided, state) => ({
      ...provided,
      borderStyle: 'solid',
      borderWidth: state.isFocused ? 2 : 1,
      borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? '#f44336' : (state.isFocused ? '#43425D' : '#c4c4c4'),
      borderRadius: 4,
      backgroundColor: state.isDisabled ? '#fafafa' : (isColored ? '#e6f3ff' : '#fff'),
      opacity: 1,
      margin: 0,
      minHeight: 41,
      padding: '0 14px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: config.error || (config.dangerIfEmpty && config.isEmpty) ? '#f44336' : '#43425D',
      },
      ...defaultCustomStyles.control(provided, state),
      ...(appendToCustomStyles.control || {})
    }),
    placeholder: (provided) => ({
      ...provided,
      color: config.dangerIfEmpty && config.isEmpty ? '#f44336' : '#808080',
      fontWeight: config.dangerIfEmpty && config.isEmpty ? 700 : 400,
      ...(appendToCustomStyles.placeholder || {})
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
      ...defaultCustomStyles.menu(provided, state),
      ...(appendToCustomStyles.menu || {})
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: 0,
      ...defaultCustomStyles.dropdownIndicator(provided, state),
      ...(appendToCustomStyles.dropdownIndicator || {})
    }),
    multiValue: (provided, state) => ({
      ...provided,
      margin: '.5rem 0 0',
      flexGrow: 1,
      width: '100%',
      color: config.error ? '#f44336' : '#43425D',
      ...defaultCustomStyles.singleValue(provided, state),
      ...(appendToCustomStyles.multiValue || {})
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      flexGrow: 1,
      ...(state.isDisabled ? {
        whiteSpace: 'normal'
      } : {}),
      ...(appendToCustomStyles.multiValueLabel || {})
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: 0,
      flexGrow: 1,
      color: config.error ? '#f44336' : '#43425D',
      ...defaultCustomStyles.singleValue(provided, state),
      ...(appendToCustomStyles.singleValue || {})
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      paddingLeft: 0,
      fontSize: '0.9285714285714286rem',
      color: config.error ? '#f44336' : '#43425D',
      ...(state.isDisabled ? {
        '& svg': {
          display: 'none'
        }
      } : {}),
      ...defaultCustomStyles.valueContainer(provided, state),
      ...(appendToCustomStyles.valueContainer || {})
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      margin: 0,
      border: 0,
      backgroundColor: 'transparent',
      ...defaultCustomStyles.indicatorSeparator(provided, state),
      ...(appendToCustomStyles.indicatorSeparator || {})
    }),
  }
}

class SelectField extends React.Component {
  state = {
    error: false,
    isEmpty: false,
    errorMessage: null,
    focused: false,
    selectedValue: ''
  }

  componentDidMount() {
    const { value, values, options } = this.props.field

    if (values) {
      const selectedValue = values.length ? values : ''

      this.setState({
        selectedValue,
        isEmpty: !selectedValue
      })
    } else if (value) {
      const currentValue = typeof value === 'object' ? (value.value || value.value === 0 ? value : '') : value

      const selectedValue = !_.isObject(value) && _.isArray(options) ? options.find(option => {
        return option.value === currentValue
      }) : value

      this.setState({
        selectedValue,
        isEmpty: !selectedValue
      })
    } else {
      this.setState({
        isEmpty: true
      })
    }

    return validators.mount(this)
  }

  componentDidUpdate(prevProps) {
    const { value: prevValue, values: prevValues } = prevProps.field

    const { field, onChange } = this.props

    const { value, values, options } = field

    let tmpValue = value || null

    let changed = false
    
    if (!_.isObject(value) && _.isArray(options)) {
      tmpValue = options.find(option => {
        return option.value === value
      })
    }

    if (_.isObject(tmpValue)) {
      changed = !prevValue || (tmpValue.value !== prevValue && tmpValue.value !== prevValue.value)
    } else if (typeof value === 'string') {
      changed = value !== prevValue
    }

    if ((prevValues || values) && !_.isEqual(prevValues, values)) {
      changed = true

      tmpValue = values
    }

    if (changed) {
      return this.setState({
        selectedValue: tmpValue,
        isEmpty: !tmpValue
      }, () => {
        onChange({type: 'select'}, field, tmpValue)
      })
    }

    return validators.update(this, prevProps)
  }

  render() {
    const { field, onChange, classes, noOptionsMessage, selectClasses, newCustomStyles, readonly, inGroup } = this.props

    const { parentValues, valueIndex, unique } = field

    const newClasses = {
      ...classes,
      ...selectClasses
    }

    const options = (typeof field.options !== 'string' ? (field.options || []) : []).filter((option, index) => {
      if (!unique || index === valueIndex) {
        return true
      }

      return parentValues.findIndex(item => {
        return item[field.name] ? item[field.name].value === option.value : false
      }) === -1
    })

    const { focused, selectedValue, isEmpty } = this.state

    const activeLabel = (selectedValue ? true : focused) || field.forceFocus

    const labelActive = (activeLabel ? ' ' + newClasses.labelActive : '')

    const fieldReadonly = readonly || field.readonly

    return (
      <FormControl fullWidth={true} error={field.error ? true : false} className={newClasses.root + (inGroup ? ' ' + classes.inGroup : '')}>
        {field.label ? (
          <InputLabel variant='outlined' className={newClasses.label + labelActive}>
            <LabelWithAsterisk>{field.label}</LabelWithAsterisk>
          </InputLabel>
        ) : ''}
        <Select
          variant='outlined'
          styles={customStyles({
            error: field.error ? true : false,
            customStyles: {
              ...newCustomStyles
            },
            appendToCustomStyles: {
              ...(field.customStyles || {}),
              ...((selectedValue || {}).customStyles || {})
            },
            dangerIfEmpty: field.dangerIfEmpty,
            isEmpty
          }, selectedValue && selectedValue.isColored)}
          isDisabled={fieldReadonly || field.disabled}
          options={options}
          value={selectedValue || ''}
          inputId={_.uniqueId()}
          placeholder={field.placeholder || false}
          isMulti={field.isMulti ? true : false}
          isClearable={field.isClearable ? true : false}
          onChange={(value) => {
            this.setState({
              selectedValue: value,
              isEmpty: !value
            })

            return onChange({ type: 'select', fromState: false }, field, field.isMulti && !value ? [] : value)
          }}
          onBlur={() => this.setState({focused: false})}
          onFocus={() => this.setState({focused: true})}
          noOptionsMessage={() => noOptionsMessage ? noOptionsMessage.noResults : null} />
        {field.error ? (
          <FormHelperText className={newClasses.formHelperText}>{field.error}</FormHelperText>
        ) : ''}
      </FormControl>
    )
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(SelectField)))
