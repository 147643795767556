import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ProgressField from '../ProgressField'

import {
} from '../../actions'

const styles = theme => ({})

class ProgressIndicator extends React.Component {
  render() {
    const { item } = this.props
    
    if (!item) return null

    const { 
      title,
      value,
      maxValue,
      unit,
      labelFormatter,
      hideLabel,
      colorThresholds
    } = item

    return (
      <ProgressField 
        title={title}
        value={value}
        maxValue={maxValue}
        unit={unit}
        labelFormatter={labelFormatter}
        hideLabel={hideLabel}
        colorThresholds={colorThresholds}
      />
    )
  }
}

ProgressIndicator.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    unit: PropTypes.string,
    hideLabel: PropTypes.bool,
    labelFormatter: PropTypes.func,
    colorThresholds: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      class: PropTypes.string
    }))
  }).isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ProgressIndicator)))
