import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import cloneDeep from 'lodash/cloneDeep'
import LabelWithAsterisk from './LabelWithAsterisk'

import {
  confirmActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: '.5rem',
  },
  image: {
    padding: '.5rem',
    border: '1px solid ' + theme.palette.grey[100],
    marginRight: '.5rem'
  },
  img: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    display: 'block',
  },
  icon: {
    width: 150,
    height: 150,
    fontSize: 160,
    padding: '1rem',
    color: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  file: {
    position: 'absolute',
    left: -900,
    height: 1,
    width: 1,
  },
  filename: {
    padding: '.5rem',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5,
    marginBottom: '.5rem'
  },
  tools: {
    marginBottom: '.5rem',
    'span': {
      fontSize: '1.2rem',
    }
  },
  replace: { 
    marginTop: '.5rem',
  },
  drop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    padding: '.5rem',
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5,
    color: theme.palette.error.dark,
    fontWeight: 700
  },
  draggingOver: {
    backgroundColor: theme.palette.grey[100],
    border: '2px dashed ' + theme.palette.grey[400],
    margin: '-2px'
  },
  draggingOverValid: {
    backgroundColor: theme.palette.success.light,
  }
})

class File extends React.Component {
  state = {
    isDraggingOver: false,
    isDraggingOverValid: false
  }

  componentDidMount = () => {
    const { value } = this.props.field

    return this.setState({
      initialValue: cloneDeep(value) || {},
      ...value
    })
  }

  base64 = (file) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      if (!file) {
        return resolve({
          base64: null
        })
      }

      reader.readAsDataURL(file)

      reader.onload = function () {
        resolve({
          base64: reader.result,
          filename: file.name,
          type: file.type,
          isNew: true,
        })
      }

      reader.onerror = function (error) {
        reject(error)
      }
    })
  }

  onChange = (event) => {
    const { field, onChange } = this.props

    const { maxFileSize } = field

    const file = event.target.files[0] || {}

    if (maxFileSize.int >= file.size) {
      return this.base64(file).then((file) => {
        this.setState({
          ...file,
          error: null
        })

        return onChange({type: 'file'}, field, file)
      })
    }

    return this.setState({
      error: ['Il file non può essere più grande di', maxFileSize.string].join(' ')
    })
  }

  onDelete = (inputId, confirm, confirmed) => {
    confirm = confirm || {
      title: 'Elimina file',
      message: 'Sei sicuro di voler eliminare questo file?',
      ok: 'Elimina',
      ko: 'Annulla'
    }

    if (confirmed) {
      const { field, onChange } = this.props

      const { initialValue } = this.state

      return this.setState({
        base64: null,
        isNew: false
      }, () => {
        const fileInput = document.getElementById(inputId)

        if (fileInput) {
          fileInput.value = ''
        }

        onChange({ type: 'file' }, field, {
          ...(initialValue.uid ? {
            ...initialValue,
            delete: true
          } : {}),
          base64: null,
        })
      })
    }

    return this.props.onHandleOpen(confirm, this.onDelete, [
      inputId,
      null,
      true
    ])
  }

  onDragOver = (event) => {
    event.preventDefault()

    this.setState({ isDraggingOver: true })
  }

  onDragLeave = () => {
    this.setState({ isDraggingOver: false, isDraggingOverValid: false })
  }

  onDrop = (event) => {
    event.preventDefault()

    const { field, onChange } = this.props

    const { maxFileSize } = field

    const file = event.dataTransfer.files[0] || {}

    if (maxFileSize.int >= file.size) {
      this.setState({ isDraggingOver: false, isDraggingOverValid: false })

      return this.base64(file).then((file) => {
        this.setState({
          ...file,
          error: null
        })
      
        return onChange({type: 'file'}, field, file)
      })
    } else {
      this.setState({ isDraggingOverValid: false })
    }
    
    return this.setState({
      error: ['Il file non può essere più grande di', maxFileSize.string].join(' ')
    })
  }

  render() {
    const { classes, field } = this.props

    const { isDraggingOver, isDraggingOverValid } = this.state

    const { base64, filename, type, error } = this.state

    const errorHtml = error ? (
      <div className={classes.error}>
        {error}
      </div>
    ) : null

    const inputId = 'fiel_' + field.name

    return (
      <div 
        className={`${classes.root} ${isDraggingOver ? classes.draggingOver : ''} ${isDraggingOverValid ? classes.draggingOverValid : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
      >
        <input className={classes.file} accept={field.accept || '*'} type='file' id={inputId} onChange={this.onChange} />
        {base64 ? (
          <React.Fragment>
            <div className={classes.image}>
              {type.startsWith('image/') ? (
                <img src={base64} alt={filename} className={classes.img} />
              ) : (
                isDraggingOver ? (
                  <Icon className={classes.icon}>file_download</Icon>
                ) : (
                  <Icon className={classes.icon}>insert_drive_file</Icon>
                )
              )}
            </div>
            <div className={classes.info}>
              {filename && (
                <div className={classes.filename}>{filename}</div>
              )}
              <div className={classes.tools}>
                <Button variant='contained' color='primary' component={'label'} htmlFor={inputId}>Cambia</Button>
                {field.isClearable && (
                  <>
                    <span> - </span>
                    <Button variant='contained' color='secondary' onClick={() => this.onDelete(inputId, field.confirm)}>Elimina</Button>
                  </>
                )}
                <div className={classes.replace}>
                  <span>Oppure rilascia qui per cambiare</span>
                </div>
              </div>
              {errorHtml}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.image}>
              {isDraggingOver ? (
                <Icon className={classes.icon}>file_download</Icon>
              ) : (
                <Icon className={classes.icon}>file_upload</Icon>
              )}
            </div>
            <div className={classes.info}>
              <div className={classes.filename}><LabelWithAsterisk>{field.label}</LabelWithAsterisk></div>
              <div className={classes.tools}>
                <Button variant='contained' color='primary' component={'label'} htmlFor={'fiel_' + field.name}>Carica</Button>
              </div>
              <div>
                <span>Oppure rilascia qui</span>
              </div>
              {errorHtml}
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

File.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  onHandleOpen: (config, callback, callbackParams = []) => {
    dispatch(confirmActions.handleOpen(config, callback, callbackParams))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(File)))
