/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Paper, Typography, Icon } from '@material-ui/core'

import {} from '../../actions'

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    padding: theme.spacing.unit * 2,
    minHeight: '100%',
    display: 'flex',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px !important',
    borderRadius: '10px !important',
    transition: 'all 0.3s ease',
    '&:hover': {
      opacity: 0.95
    }
  },
  reminderContent: {
    display: 'flex',
    width: '100%',
    '&.layout-row': {
      flexDirection: 'row',
      alignItems: 'center',
    },
    '&.layout-column': {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    }
  },
  icon: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.layout-row': {
      marginRight: theme.spacing.unit * 2,
    },
    '&.layout-column': {
      marginBottom: theme.spacing.unit * 2,
    }
  },
  content: {
    flex: 1,
    '&.layout-column': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing.unit,
    color: '#fff',
  },
  description: {
    color: 'rgba(255, 255, 255, 0.9)',
  }
})

class ActionReminderField extends React.Component {
  render() {
    const { 
      classes, 
      icon, 
      title, 
      description, 
      backgroundColor,
      titleFontSize,
      descriptionFontSize,
      iconSize,
      layout = 'row' 
    } = this.props

    return (
      <Paper 
        elevation={0} 
        className={classes.root}
        style={{ 
          background: backgroundColor || 'transparent',
        }}
      >
        <div className={`${classes.reminderContent} layout-${layout}`}>
          {icon && (
            <Icon 
              className={`${classes.icon} layout-${layout}`}
              style={{ fontSize: iconSize }}
            >
              {icon}
            </Icon>
          )}
          <div className={`${classes.content} layout-${layout}`}>
            <Typography 
              variant="subtitle1" 
              className={classes.title}
              style={{ fontSize: titleFontSize }}
            >
              {title}
            </Typography>
            <Typography 
              variant="body2" 
              className={classes.description}
              style={{ fontSize: descriptionFontSize }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </Paper>
    )
  }
}

ActionReminderField.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  titleFontSize: PropTypes.string,
  descriptionFontSize: PropTypes.string,
  iconSize: PropTypes.string,
  layout: PropTypes.oneOf(['row', 'column'])
}

const mapStateToProps = state => ({ ...state.updateReducer })

const mapDispatchToProps = dispatch => ({})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ActionReminderField)))
