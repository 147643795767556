import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import LabelWithAsterisk from './LabelWithAsterisk'

import {
  statusActions
} from '../../actions'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  label: {
    fontSize: '1.5rem'
  },
  bars: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    minWidth: 600,
    flexGrow: 1,
    alignItems: 'center',
    padding: '.5rem 0',
    fontSize: '1.125rem'
  },
  barLabel: {
    paddingRight: '1rem',
    width: '100%',
    textAlign: 'left'
  },
  progress: {
    flexGrow: 1,
    height: 6,
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  complete: {
    width: 55,
    textAlign: 'right'
  }
})

class Status extends React.Component {
  componentDidMount = () => {
    const { url } = this.props.field

    this.props.onStatusUpdate(url)
  }

  componentDidUpdate = (prevProp) => {
    const { bars: prevBars } = prevProp

    const { bars, field, appConfig, history } = this.props

    const { url, redirectAfterDone } = field

    const prevNotComplete = (prevBars[url] || [{complete: 0}]).find(item => {
      return item.complete < 100
    })

    const notComplete = (bars[url] || [{ complete: 0 }]).find(item => {
      return item.complete < 100
    })

    if (!notComplete && prevNotComplete) {

      if (!notComplete && redirectAfterDone) {
        return history.push(appConfig.appPrefix + redirectAfterDone)
      }
    }
  }

  render() {
    const { classes, field, bars } = this.props

    const { label, url } = field

    const currentBars = bars[url] || []

    return (
      <div className={classes.root}>
        <div className={classes.label}><LabelWithAsterisk>{label}</LabelWithAsterisk></div>
        <div className={classes.bars}>
          {currentBars.map((bar, index) => {
            const {
              step,
              complete = 100
            } = bar

            return (
              <div className={classes.bar} key={index}>
                <div className={classes.barLabel}>{step}</div>
                <div className={classes.progressContainer}>
                  <LinearProgress className={classes.progress} value={complete} variant='determinate' />
                  <div className={classes.complete}>{complete}%</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

Status.propTypes = {
  classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({ ...state.statusReducer, ...state.appReducer })

const mapDispatchToProps = dispatch => ({
  onStatusUpdate: (url) => {
    dispatch(statusActions.status(url))
  }
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Status)))
